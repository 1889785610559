<template>
  <div class="task-detail-root-container">
    <div class="task-detail-header-container">
      <Icon type="ios-arrow-dropleft" :size="40" @click="back" />
      <div class="task-detail-header-inner-container">
        <span style="font-size: 15px; font-weight: bold; margin-bottom: 10px"
          >@ {{ taskDetail.project_name }}</span
        >
        <span style="font-size: 12px; font-weight: bold"
          >创建于：{{ taskDetail.create_time }}</span
        >
      </div>
    </div>
    <div class="task-detail-top-contrainer">
      <div class="task-detail-row">
        <span style="font-size: 32px; font-weight: bold; margin-right: 20px">{{
          taskDetail.name
        }}</span>
        <img
          @click="edit"
          style="width: 23px; height: 23px; cursor: pointer"
          :src="icons.edit"
        />
      </div>
      <div class="task-detail-row" style="margin-top: 30px">
        <Button size="large" type="primary" @click="invite"
          >邀请任务成员</Button
        >
        <avatar-list
          :member-list="taskDetail.user_arr"
          :max-count="7"
          style="margin-left: 30px"
        />
      </div>
    </div>
    <div class="task-detail-bottom-container">
      <Button
        @click="archive"
        v-if="taskDetail.state == 1"
        class="task-detail-archive"
        type="primary"
        icon="ios-filing-outline"
        >归档该任务</Button
      >
      <slide-tabber ref="slider" :tabs="tabs" @on-change="tabChange" />
      <div class="router-scroll">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
    <task-name-drawer ref="taskNameDrawer" @refresh="getDetail" />
    <task-member-drawer ref="taskMemberDrawer" @refresh="getDetail" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AvatarList from "../../components/List/AvatarList";
import TaskNameDrawer from "./Drawer/TaskNameDrawer";
import TaskMemberDrawer from "./Drawer/TaskMemberDrawer";
import SlideTabber from "../../components/SlideTabbar";
import * as icon from "../../common/icon";
import moment from "moment";
export default {
  components: {
    "avatar-list": AvatarList,
    "task-name-drawer": TaskNameDrawer,
    "task-member-drawer": TaskMemberDrawer,
    "slide-tabber": SlideTabber,
  },
  data() {
    return {
      icons: icon,
      taskId: 0,
      taskDetail: {
        user_arr: [],
      },
      projectId: 0,
      currentMenu: "task-info",
      tabs: [
        {
          name: "任务设置",
          count: 0,
        },
        {
          name: "清单列表",
          count: 0,
        },
        {
          name: "活动",
          count: 0,
        },
        {
          name: "附件",
          count: 0,
        },
      ],
    };
  },
  mounted() {
    this.taskId = this.$router.currentRoute.params.tid;
    this.projectId = this.$router.currentRoute.params.pid;
    this.getDetail();

    // 刷新页面后控制选中
    this.currentMenu = this.$router.currentRoute.name;
    switch (this.currentMenu) {
      case "task-info":
        this.$refs.slider.switchTo(0);
        break;
      case "task-list":
        this.$refs.slider.switchTo(1);
        break;
      case "task-active":
        this.$refs.slider.switchTo(2);
        break;
      case "task-accessories":
        this.$refs.slider.switchTo(3);
        break;
    }
  },
  methods: {
    ...mapActions({
      taskArchiveAction: "taskArchive",
      taskGetTaskDetailAction: "taskGetTaskDetail",
      projectGetJoinedMemberListAction: "projectGetJoinedMemberList",
    }),
    archive() {
      this.taskArchiveAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
      })
        .then((res) => {
          this.$router.push({
            name: "task-archive",
            params: {
              id: this.projectId,
            },
          });
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getDetail() {
      this.taskGetTaskDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
      })
        .then((res) => {
          res.create_time = moment(res.create_time).format("YYYY/MM/DD HH:mm");
          this.taskDetail = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    back() {
      this.$router.push({
        name: "task-dashboard",
        params: {
          id: this.projectId,
        },
      });
    },
    edit() {
      this.$refs.taskNameDrawer.open(this.taskDetail.name);
    },
    invite() {
      this.$refs.taskMemberDrawer.open();
    },
    tabChange(index) {
      let name = "";
      let currentMenu = this.$router.currentRoute.name;
      switch (index) {
        case 0:
          name = "task-info";
          break;
        case 1:
          name = "task-list";
          break;
        case 2:
          name = "task-active";
          break;
        case 3:
          name = "task-accessories";
          break;
      }
      if (currentMenu == name) {
        return;
      }
      this.$router.push({
        name: name,
        params: {
          tid: this.taskId,
          pid: this.projectId,
        },
      });
    },
  },
};
</script>

<style scoped>
.task-detail-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #e8e9ee;
  overflow: hidden;
}
.task-detail-header-container {
  background-color: #e8e9ee;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}
.task-detail-header-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.task-detail-top-contrainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.task-detail-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.task-detail-bottom-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 30px 30px 0 0;
  background-color: #f9f9f9;
  margin-top: 80px;
  padding-top: 20px;
}
.task-detail-archive {
  position: absolute;
  top: 20px;
  right: 50px;
}
.router-scroll {
  flex: 1;
  width: 800px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
</style>