<template>
  <my-drawer ref="taskDrawer" title="邀请任务成员">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-select-people v-model="member_id" :data-source="memberList" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MySelectPeople from "../../../components/Form/MySelectPeople";
import MyInput from "../../../components/Form/MyInput";
import MyDrawer from "../../../components/MyDrawer";
import MyAvatar from "../../../components/MyAvatar";
export default {
  components: {
    "my-avatar": MyAvatar,
    "my-drawer": MyDrawer,
    "my-input": MyInput,
    "my-select-people": MySelectPeople,
  },
  data() {
    return {
      projectId: 0,
      taskId: 0,
      member_id: 0,
      memberList: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.pid;
    this.taskId = this.$router.currentRoute.params.tid;
  },
  methods: {
    ...mapActions({
      taskInviteTaskMemberAction: "taskInviteTaskMember",
      projectGetJoinedMemberListAction: "projectGetJoinedMemberList",
    }),
    open() {
      this.$refs.taskDrawer.open();
      this.projectGetJoinedMemberListAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          for (let item of res) {
            item.contact_id = item.client_user_id;
            item.name = item.client_name;
            item.phone = item.phone;
            item.email = item.email;
          }
          this.memberList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    submit() {
      this.taskInviteTaskMemberAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
        member_id: this.member_id,
      })
        .then((res) => {
          this.$emit('refresh');
          this.$refs.taskDrawer.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.board-form-image {
  width: 100px;
  height: 118px;
}
.task-member-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>